import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Announcement from '../components/announcement'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>FACCi</title>
        <meta
          name="description"
          content="FACCi: AI Personalized Fashion E-Commerce. Scan your closet, virtual fittings, and AI-powered style recommendations. Featured in Cyberport, HKSTP, CUHK, YDC."
        />
        <meta property="og:title" content="FACCi" />
        <meta
          property="og:description"
          content="FACCi: AI Personalized Fashion E-Commerce. Scan your closet, virtual fittings, and AI-powered style recommendations. Featured in Cyberport, HKSTP, CUHK, YDC."
        />
      </Helmet>
      <div className="home-hero">
        <header className="home-heading">
          <div id="notifcation" className="home-notification">
            <Link to="/">
              <Announcement
                rootClassName="announcement-root-class-name"
                className="home-component"
              ></Announcement>
            </Link>
          </div>
          <Navbar></Navbar>
        </header>
        <div className="home-content">
          <div className="home-content01">
            <img
              alt="image"
              src="/facci/full%20logo.svg"
              className="home-image"
            />
            <h1 className="home-title">
              AI Personalized Fashion E-Commerce Platform
            </h1>
            <span className="home-caption">
              We’re consumer’s stylist &amp; shop’s sales expert.
            </span>
          </div>
          <div className="home-images">
            <div className="home-column">
              <img
                alt="pastedImage"
                src="/facci/virtual%20try%20on%20running-300w.png"
                className="home-pasted-image"
              />
            </div>
            <div className="home-column1">
              <img
                alt="pastedImage"
                src="/facci/home-400h.png"
                className="home-pasted-image1"
              />
              <img
                alt="pastedImage"
                src="/facci/market%20reels-400h.png"
                className="home-pasted-image2"
              />
            </div>
            <div className="home-column2">
              <img
                alt="pastedImage"
                src="/facci/item%20after%20scanning-300w.png"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home-sections">
        <div className="home-section">
          <div className="home-image1">
            <div className="home-image-highlight">
              <span className="home-text">
                <span>Your closet&apos;s notebook.</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-content02">
            <h2 className="home-text03">Scan Your Closet</h2>
            <div className="home-highlight">
              <span className="home-text04">
                Consumers can access their clothes anytime &amp; anywhere
              </span>
              <span className="home-text05">
                <span>- Auto tagging and categorizing</span>
                <br></br>
                <span>- Outfit visualizations</span>
                <br></br>
                <span>- Create Lookbook</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
        <div className="home-section1">
          <div className="home-content03">
            <div className="home-heading1">
              <h2 className="home-text12">Virtual Fitting</h2>
              <span className="home-text13">Based on diffusion approach.</span>
            </div>
            <div className="home-content04">
              <div className="home-points">
                <div className="home-point">
                  <div className="home-check">
                    <svg viewBox="0 0 1024 1024" className="home-icon">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <span className="home-text14">
                    Enhanced customer experience
                  </span>
                </div>
                <div className="home-point1">
                  <div className="home-check1">
                    <svg viewBox="0 0 1024 1024" className="home-icon2">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <span className="home-text15">Increased convenience</span>
                </div>
                <div className="home-point2">
                  <div className="home-check2">
                    <svg viewBox="0 0 1024 1024" className="home-icon4">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <span className="home-text16">Reduced returns</span>
                </div>
                <div className="home-point3">
                  <div className="home-check3">
                    <svg viewBox="0 0 1024 1024" className="home-icon6">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <span className="home-text17">
                    Gain confidence in purchases
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-image2"></div>
          <div className="home-image3"></div>
        </div>
        <div className="home-section2">
          <div className="home-image4"></div>
          <div className="home-content05">
            <h2 className="home-text18">
              <span>Next-gen B2B2C marketplace</span>
              <br></br>
            </h2>
            <div className="home-accordion">
              <div className="home-details">
                <span className="home-text21">
                  AI-personalized recommendations
                </span>
                <span className="home-text22">
                  We provide suggestions and bundled sales based on occasions,
                  style, weather, and any personal preference.
                </span>
              </div>
              <div className="home-details1">
                <span className="home-text23">Cross-store bundled outfit</span>
                <span className="home-text24">
                  Increase brands&apos; exposure through being mix-and-matched
                  with other brands&apos; item. Lower your marketing and
                  advertising costs.
                </span>
              </div>
              {/* <div className="home-details2">
                <span className="home-text25">Short video sales channel</span>
                <span className="home-text26">
                  Leverage the power of reels. Consumers can purchase the entire
                  set of clothing presented in your videos in just 1 click.
                </span>
              </div> */}
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner-container">
        <div className="home-banner">
          <div className="home-overlay">
            <span className="home-text27">
              <span>
                Be our pilot
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>merchants!</span>
            </span>
            <a
              href="mailto:leochan@facci.ltd?subject=Interest in brand onboarding"
              className="home-link"
            >
              <div className="home-book-btn">
                <span className="home-text30">Book a demo</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="home-header">
          <div className="home-tag">
            <span className="home-text31">Fundings and Awards</span>
          </div>
          <div className="home-heading2">
            <h2 className="home-text32">Featured in</h2>
          </div>
        </div>
        <div className="home-feature-list">
          <div className="home-feature">
            <img
              alt="image"
              src="/facci/cyberport.svg"
              className="home-image5"
            />
            <div className="home-content06">
              <span className="home-title1">Hong Kong Cyberport</span>
              <span className="home-description">
                Cyberport Creative Microfund grantee
              </span>
            </div>
          </div>
          <div className="home-feature1">
            <img alt="image" src="/facci/hkstp.svg" className="home-image6" />
            <div className="home-content07">
              <span className="home-title2">
                Hong Kong Science &amp; Technology Parks
              </span>
              <span className="home-description1">
                Techathon 2024+, Digital Economy, 2nd Runner-up
              </span>
            </div>
          </div>
          <div className="home-feature2">
            <img alt="image" src="/facci/cuhk.svg" className="home-image7" />
            <div className="home-content08">
              <span className="home-title3">PI Centre - CUHK</span>
              <span className="home-description2">Pilots Lite Programme</span>
            </div>
          </div>
          <div className="home-feature3">
            <img alt="image" src="/facci/ydc.jpg" className="home-image8" />
            <div className="home-content09">
              <span className="home-title4">The Young Entrepreneurs Development Council</span>
              <span className="home-description3">
                2024 Dare to Change, HKSTPC Golden Technopreneur Award
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Home

import React from 'react'

import PropTypes from 'prop-types'

import Social from './social'
import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-content">
        <div className="footer-information">
          <div className="footer-heading">
            <img
              alt={props.pastedImageAlt}
              src={props.pastedImageSrc}
              className="footer-pasted-image"
            />
            <span className="footer-text">{props.text1}</span>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/facci-ltd"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link"
            >
              <Social
                rootClassName="social-root-class-name"
                className="footer-component"
              ></Social>
            </a>
            <a
              href="https://www.instagram.com/facci.ootd"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link1"
            >
              <Social
                insiderSrc="/pastedimage-k0l6.svg"
                rootClassName="social-root-class-name1"
                className="footer-component1"
              ></Social>
            </a>
          </div>
        </div>
        <div className="footer-links"></div>
      </div>
      <span className="footer-text1">{props.text}</span>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  text1: 'AI Personalised Fashion E-Commerce Platform',
  pastedImageSrc: '/facci/full%20logo.svg',
  pastedImageAlt: 'pastedImage',
  text: '© 2024 FACCi. All Rights Reserved.',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  pastedImageSrc: PropTypes.string,
  pastedImageAlt: PropTypes.string,
  text: PropTypes.string,
}

export default Footer

import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <header className="navbar-navbar">
      <div className="navbar-desktop">
        <div className="navbar-main">
          <Link to="/" className="navbar-navlink">
            <img
              alt={props.brandingAlt}
              src={props.brandingSrc}
              className="navbar-finbest"
            />
          </Link>
        </div>
      </div>
    </header>
  )
}

Navbar.defaultProps = {
  link3: 'Prices\n',
  text1: 'Features',
  text12: 'Prices',
  link4: 'Contact\n',
  brandingSrc: '/facci/logo.svg',
  link1: 'Features',
  text131: 'Log in',
  imageAlt: 'image',
  pastedImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4a46e3f6-a2fb-4002-a0ce-bb246bd8698d/191f70d5-9a02-4b3a-a398-d21659aa57d0?org_if_sml=1195',
  text1311: 'Sign up',
  text13: 'Contact',
  link2: 'How it works',
  pastedImageAlt: 'pastedImage',
  imageSrc: 'afed3d97-066a-440a-a27e-491fdc7ac2b5',
  brandingAlt: 'pastedImage',
  text11: 'How it works',
}

Navbar.propTypes = {
  link3: PropTypes.string,
  text1: PropTypes.string,
  text12: PropTypes.string,
  link4: PropTypes.string,
  brandingSrc: PropTypes.string,
  link1: PropTypes.string,
  text131: PropTypes.string,
  imageAlt: PropTypes.string,
  pastedImageSrc: PropTypes.string,
  text1311: PropTypes.string,
  text13: PropTypes.string,
  link2: PropTypes.string,
  pastedImageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  brandingAlt: PropTypes.string,
  text11: PropTypes.string,
}

export default Navbar
